import gql from 'graphql-tag';
import { TAP_FIELDS, BEVERAGE_FIELDS, BEVERAGE_CATEGORY_FIELDS } from '../../data/graphql';

export const GET_TAPS = gql`
    query GetTaps($location: MongoID!){
        taps: tapMany(filter: { location: $location }) {
            ${TAP_FIELDS}
        }
    }
`;

export const GET_TAPS_BY_STATUS = gql`
    query GetTapsByStatus($location: MongoID!, $statuses: [EnumTapStatus]){
        taps: tapMany(filter: { location: $location, _operators: {status: {in: $statuses} }}) {
            ${TAP_FIELDS}
        }
    }
`;

export const GET_TAP_BY_ID = gql`
    query GetTapById($id: MongoID!){
        tap: tapById(_id: $id) {
            ${TAP_FIELDS}
        }
    }
`;

export const SEARCH_BEERS = gql`
    query searchBeers($searchText:String!, $type:String!){
        search(text:$searchText, type:$type){
            ${BEVERAGE_FIELDS}
        }
    }
`;

export const GET_BEVERAGE_CATEGORIES = gql`
    query GetCategories {
        categories: beverageCategoryMany {
            ${BEVERAGE_CATEGORY_FIELDS}
        }
    }
`;
