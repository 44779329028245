import React, { useState } from 'react';
import { Grid, Button, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import IconBeer from '../../../images/icon-beer.svg';
import IconWine from '../../../images/icon-wine.svg';
import IconSpirit from '../../../images/icon-spirit.svg';
import IconOther from '../../../images/icon-beverage-other.svg';

import { BeerSearch } from './BeerSearch';
import { ActionBar } from '../../../components/ActionBar';

const useStyles = makeStyles((theme) => ({
  beverageTypeCard: {
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: 10,
    textAlign: 'center',
    '& img': {
      width: 'auto',
      height: '75px'
    },
    '& p': {
      marginBottom: '0',
      textAlign: 'center',
      fontFamily: ['Unica One', 'Roboto', 'sans-serif'],
      textTransform: 'uppercase',
      fontWeight: 'bold',
      marginTop: '30px'
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      borderColor: 'transparent'
    }
  }
}));

export const NewBeverageTypeSelection = ({ onSelect }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [interstitialType, setInterstitialType] = useState(null);
  const [searchType, setSearchType] = useState(null);

  const confirmType = (interstitialType) => {
    if (interstitialType === 'Beer') {
      setSearchType('Beer');
    } else {
      const beverage = {
        type: interstitialType
      };
      onSelect(beverage);
    }
  };

  const handleSelectBeverage = (beverage) => {
    onSelect(beverage);
  };

  return (
    <>
      {!searchType && (
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <h2 style={{ textAlign: 'center', margin: '0 0 10px 0' }}>{t('NewBeverageSelection.TypeBeverage')}</h2>
          </Grid>

          <div className="flex w-1/2 md:w-1/4">
            <Card
              variant="outlined"
              className={clsx(
                classes.beverageTypeCard,
                {
                  active: interstitialType === 'Beer'
                },
                'w-full'
              )}
              onClick={() => setInterstitialType('Beer')}>
              <CardContent className="flex flex-col items-center">
                <img src={IconBeer} alt="Beer or Cider" />
                <p>{t('NewBeverageSelection.BeerCider')}</p>
              </CardContent>
            </Card>
          </div>

          <div className="flex w-1/2 md:w-1/4">
            <Card
              variant="outlined"
              className={clsx(
                classes.beverageTypeCard,
                {
                  active: interstitialType === 'Wine'
                },
                'w-full'
              )}
              onClick={() => setInterstitialType('Wine')}>
              <CardContent className="flex flex-col items-center">
                <img src={IconWine} alt="Wine" />
                <p>{t('NewBeverageSelection.Wine')}</p>
              </CardContent>
            </Card>
          </div>
          <div className="flex w-1/2 md:w-1/4">
            <Card
              variant="outlined"
              className={clsx(
                classes.beverageTypeCard,
                {
                  active: interstitialType === 'Spirit'
                },
                'w-full'
              )}
              onClick={() => setInterstitialType('Spirit')}>
              <CardContent className="flex flex-col items-center">
                <img src={IconSpirit} alt="Spirit" />
                <p>{t('NewBeverageSelection.SpiritCocktail')}</p>
              </CardContent>
            </Card>
          </div>
          <div className="flex w-1/2 md:w-1/4">
            <Card
              variant="outlined"
              className={clsx(
                classes.beverageTypeCard,
                {
                  active: interstitialType === 'Other'
                },
                'w-full'
              )}
              onClick={() => setInterstitialType('Other')}>
              <CardContent className="flex flex-col items-center">
                <img src={IconOther} alt="Other" />
                <p>{t('NewBeverageSelection.Other')}</p>
              </CardContent>
            </Card>
          </div>
          <ActionBar>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!interstitialType}
              onClick={() => confirmType(interstitialType)}>
              {t('NewBeverageSelection.Next')}
            </Button>
          </ActionBar>
        </Grid>
      )}
      {searchType === 'Beer' && <BeerSearch onSelect={handleSelectBeverage} />}
    </>
  );
};
