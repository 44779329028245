import React, { useContext, useState } from 'react';
import { Button, Fab, Hidden, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Add, Code as CodeIcon, CropFree, PrintOutlined } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { camelCase, sortBy } from 'lodash';
import clsx from 'clsx';

import { SessionContext } from '../../contexts/session';

import { Layout } from '../../components/Layout';
import { TapListItem } from './TapListItem';
import { FeedbackBar } from '../../components/FeedbackBar';
import { Loading } from '../../components/Loading';

import EmptyTapListImage from '../../images/taps.png';
import IconPremiumChip from '../../images/icon-premium-chip.svg';

import { GET_TAPS } from './queries';
import { PrintDialog } from './print/PrintDialog';
import { EmbedDialog } from './embed/EmbedDialog';
import { QrCodeDialog } from './qrCode/QrCodeDialog';
import TapListSectionTitle from './TapListSectionTitle';
import { UserVesselsProvider } from '../../contexts/userVessels';
import PremiumFeatureDialog from '../../components/PremiumFeatureDialog';
import CustomLink from '../../components/CustomLink';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& [aria-label="print"]': {
      height: 48,
      width: 48,
      marginRight: 10
    }
  },
  editDrawer: {
    minHeight: '400px',
    padding: '20px'
  },
  drawerPaper: {
    borderTopRightRadius: '40px',
    borderTopLeftRadius: '40px'
  },

  emptyListContainer: {
    padding: '80px 30px',
    textAlign: 'center',

    '& img': {
      width: 140
    },

    '& p': {
      padding: '20px 0'
    }
  }
}));

export const Taps = withRouter(({ history }) => {
  const { t } = useTranslation();
  let session = useContext(SessionContext);
  const { currentLocation, premium } = session;
  const [showPremiumFeatureDialog, setShowPremiumFeatureDialog] = useState(false);
  const [premiumFeatureName, setPremiumFeatureName] = useState('');
  const [showPrint, setShowPrint] = useState(false);
  const [showEmbed, setShowEmbed] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);

  const edit = (tap) => {
    if (tap) history.push(`/taps/${tap._id}/edit`);
    else history.push(`/taps/new`);
  };

  const handlePrint = () => {
    if (premium) {
      setShowPrint(true);
    } else {
      setPremiumFeatureName(t('PremiumFeatureDialog.Features.PrintedMenus'));
      setShowPremiumFeatureDialog(true);
    }
  };

  const handleEmbed = () => {
    if (premium) {
      setShowEmbed(true);
    } else {
      setPremiumFeatureName(t('PremiumFeatureDialog.Features.WebsiteEmbed'));
      setShowPremiumFeatureDialog(true);
    }
  };

  const handleQrCode = (state) => () => {
    if (premium) {
      setShowQrCode(state);
    } else {
      setPremiumFeatureName(t('PremiumFeatureDialog.Features.QRCodeMenus'));
      setShowPremiumFeatureDialog(true);
    }
  };

  const classes = useStyles();

  const { data, loading, error } = useQuery(GET_TAPS, {
    variables: { location: currentLocation?._id },
    fetchPolicy: 'cache-and-network',
    skip: !currentLocation
  });

  const taps = sortBy(data?.taps || [], (t) => t.name);

  // Split all taps by status into active, on_deck and inactive.
  const { activeTaps, onDeckTaps, inactiveTaps } = taps.reduce(
    (acc, t) => {
      const statusKey = `${camelCase(t.status)}Taps`;
      acc[statusKey] = [...acc[statusKey], t];
      return acc;
    },
    { activeTaps: [], onDeckTaps: [], inactiveTaps: [] }
  );

  return (
    <UserVesselsProvider>
      <Layout title={t('Taps.TapMenu')}>
        <FeedbackBar message={error} />
        <PrintDialog open={showPrint} tapsCount={taps.length} onCancel={() => setShowPrint(false)} />
        <EmbedDialog open={showEmbed} tapsCount={taps.length} onCancel={() => setShowEmbed(false)} />
        <QrCodeDialog open={showQrCode} onCancel={handleQrCode(false)} />
        {loading && <Loading style={{ margin: 40 }} />}
        {!loading && taps.length === 0 && (
          <div className="w-full flex flex-col items-center">
            <div item xs={12} md={6} className={clsx(classes.emptyListContainer, 'flex max-w-lg flex-col items-center')}>
              <Typography variant="h2">{t('Taps.NoTapsYet')}</Typography>
              <img alt="No Taps" src={EmptyTapListImage} />
              <p>
                {t('Taps.TapsAreBuildingBlocks')} <CustomLink href="/screens">{t('Taps.Screens')}</CustomLink>.
              </p>
              <Button variant="contained" color="primary" onClick={() => edit()}>
                {t('Taps.AddYourFirstTap')}
              </Button>
            </div>
          </div>
        )}
        {!loading && taps.length > 0 && (
          <>
            <Alert severity="info" variant="standard" style={{ margin: '0 30px' }}>
              <div>
                {t('Taps.GreatWhenYouAreDoneAddingTaps')},{' '}
                <Link href="/screens" underline="always">
                  {t('Taps.CreateAScreen')}
                </Link>{' '}
                {t('Taps.GetMenuOnTvs')}
              </div>
            </Alert>
            {activeTaps.map((s) => (
              <TapListItem key={s._id} tap={s} onClick={() => edit(s)} />
            ))}

            {onDeckTaps.length > 0 && (
              <>
                <TapListSectionTitle title="On Deck" />
                {onDeckTaps.map((s) => (
                  <TapListItem key={s._id} tap={s} onClick={() => edit(s)} />
                ))}
              </>
            )}

            {inactiveTaps.length > 0 && (
              <>
                <TapListSectionTitle title={t('Taps.InactiveSectionTitle')} />
                {inactiveTaps.map((s) => (
                  <TapListItem key={s._id} tap={s} onClick={() => edit(s)} />
                ))}
              </>
            )}
            <div className={classes.fab}>
              <Hidden xsDown>
                <Fab
                  variant="circular"
                  color={premium ? 'primary' : 'default'}
                  aria-label="print"
                  onClick={handleQrCode(true)}
                  className="relative">
                  <CropFree />
                  {!premium && <img alt="Icon Premium Feature" src={IconPremiumChip} className="absolute top-[-6px] right-[-6px]" />}
                </Fab>
                <Fab variant="circular" color={premium ? 'primary' : 'default'} aria-label="print" onClick={handleEmbed}>
                  <CodeIcon />
                  {!premium && <img alt="Icon Premium Feature" src={IconPremiumChip} className="absolute top-[-6px] right-[-6px]" />}
                </Fab>
                <Fab variant="circular" color={premium ? 'primary' : 'default'} aria-label="print" onClick={handlePrint}>
                  <PrintOutlined />
                  {!premium && <img alt="Icon Premium Feature" src={IconPremiumChip} className="absolute top-[-6px] right-[-6px]" />}
                </Fab>
              </Hidden>
              <Fab variant="extended" color="primary" aria-label="add" onClick={() => edit()}>
                <Add /> {t('Taps.NewTap')}
              </Fab>
            </div>
          </>
        )}
      </Layout>
      <PremiumFeatureDialog
        show={showPremiumFeatureDialog}
        featureName={premiumFeatureName}
        onClose={() => setShowPremiumFeatureDialog(false)}
      />
    </UserVesselsProvider>
  );
});
