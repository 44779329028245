import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { Layout } from '../../components/Layout';
import { TapEditForm } from './TapEditForm';
import { SessionContext } from '../../contexts/session';
import { FeedbackBar } from '../../components/FeedbackBar';

import { GET_TAPS } from './queries';
import { UserVesselsProvider } from '../../contexts/userVessels';

export const NewTap = withRouter(({ history }) => {
  const { currentLocation } = useContext(SessionContext);
  const { t } = useTranslation();

  let tap = { name: 1, beverage: {} };

  const { loading, error, data } = useQuery(GET_TAPS, {
    variables: { location: currentLocation?._id },
    fetchPolicy: 'cache-and-network'
  });

  // figure out what number tap handle this should be
  // if we're missing one (maybe they deleted one), choose that number
  // otherwise, max + 1
  const tapNumbers = (data?.taps || []).map((s) => s.name);
  if (tapNumbers.length > 0) {
    let [min, max] = [1, Math.max(...tapNumbers)];
    let out = Array.from(Array(max - min), (v, i) => i + min).filter((i) => !tapNumbers.includes(i));
    if (out.length > 0) tap.name = out[0];
    else tap.name = max + 1;
  }

  return (
    <UserVesselsProvider>
      <Layout title={t('TapsScreen.NewTap')}>
        <FeedbackBar message={error} />
        {!loading && <TapEditForm tap={tap} onClose={() => history.push('/taps')} />}
      </Layout>
    </UserVesselsProvider>
  );
});
