import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 24,
    padding: '1.5rem',
    borderBottom: `1px solid #ddd`
  },
  title: {
    textTransform: 'uppercase',
    margin: '0 auto'
  }
}));

const TapListSectionTitle = ({ title }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Typography className={classes.title} variant="h1">
        {title}
      </Typography>
    </Grid>
  );
};

export default TapListSectionTitle;
