import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { sortBy, isNil } from 'lodash';

import { getServingPriceLabel, getServingSizeLabel, resolveVesselName } from '../../util/lang';
import { SelectedBoxBackgroundColor } from '../../theme';
import { UserVesselsContext } from '../../contexts/userVessels';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 15,
    padding: '1.5rem',
    borderBottom: `1px solid #ddd`,
    cursor: 'pointer',

    '&:hover': {
      background: SelectedBoxBackgroundColor
    }
  },
  itemHandle: {
    display: 'inline-block',
    border: '1px solid #DDD',
    borderRadius: '100%',
    height: '40px',
    width: '40px',
    lineHeight: '38px',
    textAlign: 'center',
    fontSize: '13px',
    marginTop: '4px'
  },
  itemName: {
    fontWeight: '800',
    fontSize: '2em',
    lineHeight: '1.45',
    marginBottom: '5px'
  },
  itemSource: {
    fontSize: '1.2em',
    lineHeight: '1.2',
    fontFamily: '"Unica One", Roboto, sans-serif',
    textTransform: 'uppercase',
    marginBottom: '4px'
  },
  itemStyle: {
    fontSize: '1.2em',
    lineHeight: '1.2',
    fontFamily: '"Unica One", Roboto, sans-serif',
    textTransform: 'uppercase',
    opacity: '0.7'
  },

  servingContainer: {
    display: 'flex'
  },
  servingItem: {
    padding: '0 10px 0 0',
    marginBottom: 10,
    textAlign: 'center'
  },
  servingItemPrice: {
    fontWeight: 800,
    fontSize: '1.5em'
  },
  servingItemVessel: {
    fontSize: '1.2em',
    lineHeight: '1.2',
    fontFamily: '"Unica One", Roboto, sans-serif',
    textTransform: 'uppercase',
    opacity: '0.7'
  },
  servingItemSize: {
    fontSize: '1.2em',
    lineHeight: '1.2',
    fontFamily: '"Unica One", Roboto, sans-serif',
    textTransform: 'uppercase',
    opacity: '0.7'
  }
}));

export const TapListItem = ({ tap, onClick }) => {
  const classes = useStyles();
  const abv = isNil(tap.beverage?.abv) ? 'N/A' : tap.beverage?.abv;
  const ibu = tap.beverage?.ibu;
  return (
    <Grid container className={classes.container} onClick={() => onClick()}>
      <Grid item xs={2} sm={1}>
        {tap.status !== 'INACTIVE' && <span className={classes.itemHandle}>{tap.name}</span>}
      </Grid>
      <Grid item xs={10}>
        <Grid item xs={12}>
          <div className={tap.status !== 'INACTIVE' ? classes.itemName : `${classes.itemName} ${classes.itemDisabled}`}>
            {tap.beverage?.name || ''}
          </div>
          <div className={classes.itemSource}>{tap.beverage?.source?.name || ''}</div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.itemStyle}>
            {tap.beverage?.style || ''} - ABV {abv}% {ibu ? `- ${ibu} IBUs` : ''}
          </div>
        </Grid>
        <Grid container item xs={12} className={classes.servingContainer}>
          {sortBy(tap.servings, (s) => s.size).map((s) => (
            <BeverageServing key={s._id} serving={s} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const BeverageServing = ({ serving }) => {
  const classes = useStyles();
  const { userVessels } = useContext(UserVesselsContext);

  return (
    <div className={classes.servingItem}>
      <div className={classes.servingItemPrice}>{getServingPriceLabel(serving, true)}</div>
      <div className={classes.servingItemVessel}>{resolveVesselName(serving.vessel, userVessels)}</div>
      <div className={classes.servingItemSize}>{getServingSizeLabel(serving)}</div>
    </div>
  );
};
