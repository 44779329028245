import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { humanReadableSize } from '../util/files';
import { FontFamilyUnicaOne } from '../theme';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  fileUpload: {
    overflow: 'hidden',
    position: 'relative',
    '-webkit-font-smoothing': 'antialiased',
    backgroundColor: '$green',
    borderRadius: '5px',
    color: 'white',
    display: 'inline-block',
    fontSize: '1em',
    lineHeight: '1',
    padding: '0.75em 1em',
    textDecoration: 'none',
    fontWeight: 'normal',
    cursor: 'pointer',

    '[type=file]': {
      cursor: 'inherit',
      display: 'block',
      fontSize: '999px',
      filter: 'alpha(opacity=0)',
      minHeight: '100%',
      minWidth: '100%',
      opacity: '0',
      position: 'absolute',
      right: '0',
      textAlign: 'right',
      top: '0'
    }
  },
  preview: {
    maxWidth: '100%'
  },
  fileSizeInfo: {
    fontSize: '0.8em',
    opacity: 0.6,
    fontFamily: FontFamilyUnicaOne,
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5
  }
}));

const FileUpload = ({ onFileSelected, maxFileSize, id }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const _handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      onFileSelected(file, reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <input
        accept="image/*"
        className={classes.input}
        style={{ display: 'none' }}
        id={`raised-button-file-${id}`}
        type="file"
        onChange={_handleImageChange}
      />
      <label htmlFor={`raised-button-file-${id}`}>
        <Button variant="contained" color="primary" component="span" className={classes.button}>
          {t('FileUpload.SelectFile')}
        </Button>
      </label>
      <div className={classes.fileSizeInfo}>
        ({t('FileUpload.MaxSize')} {humanReadableSize(maxFileSize)})
      </div>
    </>
  );
};

export default FileUpload;
